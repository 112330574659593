import { NextPage } from 'next'
import { UIGlobalsContextProvider } from 'ui/lib/ui-globals'
import { defaultImage } from '#/src/images'
import React from 'react'
import dynamic from 'next/dynamic'

const ClientApp = dynamic(() => import('#/src/_nextComponents/ClientApp'), { ssr: false })

const StaticPage: NextPage = () => {
  return (
    <UIGlobalsContextProvider
      providerProps={{
        defaultImgSrc: defaultImage,
        mobileItemHeight: '75vw',
        desktopItemHeight: 'auto',
        badgeSize: '.25rem .5rem'
      }}>
      <ClientApp />
    </UIGlobalsContextProvider>
  )
}

export default StaticPage
